@import "../../App.scss";

.career_details {
    // padding-top: 20px;
    // background: #fafafa;

    .job_abt {
        padding: 40px;
        padding-top: 0;
        background: #FFFFFF;
    }

    .job_intro {
        padding: 40px 0 10px;
        position: sticky;
        top: 50px;
        background: #FFFFFF;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        border-bottom: 1px solid #ccc;

        button {
            flex-shrink: 0;
            width: 130px;
            height: 45px;
            font-size: 16px;
        }

        .loc {
            font-size: 14px;
            color: $blue-color;
        }
    }

    .form-file {
        input {
            margin-top: 10px;
        }
    }

    .job_content {
        ul {
            list-style: unset;
            padding-left: 20px;
        }
    }

    .job_form {
        padding: 20px;

        @media (max-width:786px) {
            padding: 0;
        }

        .btn {
            background-color: $red-color;
        }

        .notice {
            color: $red-color;
        }
    }

    .company_abt {
        padding: 40px;
        background: #FFFFFF;

        img {
            max-width: 250px;
            margin-bottom: 20px;
        }

        p {
            font-size: 14px;
        }
    }


    .iradio_minimal-yellow {
        position: relative;
        margin-right: 10px;
        display: flex;
    }


}

.jobop__container {
    padding: 35px;
    border: 1px solid hsla(0, 0%, 85.5%, .4);
    margin-bottom: 20px;
    transition: .2s ease-in-out;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .job__title {
        font-weight: 700;
        font-size: 1rem;
        margin-bottom: 0.8125rem;
    }

    p {
        margin-bottom: 0.5rem;
    }

    p.countrys {
        color: $blue-color;
        font-size: 1rem;
    }

    &:hover {
        -webkit-box-shadow: 0 0 0.375rem #f0f0f0;
        box-shadow: 0 0 0.375rem #f0f0f0;
    }

    .appy-action {
        display: flex;
        justify-content: flex-end;
    }

    h3.section-heading {
        font-size: 20px;
    }
}

@media (max-width:786px) {
    .career_details {
        // padding-top: 20px;
        background: #fafafa;

        .company_abt,
        .job_abt {
            padding: 20px;
        }
    }

    .jobop__container {
        padding: 15px;
        flex-direction: column;


        .appy-action {
            margin-top: 10px;
            display: flex;
            justify-content: flex-end;
        }
    }
}