@import '../../App.scss';

.jobop__container {
    padding: 35px;
    border: 1px solid hsla(0, 0%, 85.5%, .4);
    margin-bottom: 20px;
    transition: .2s ease-in-out;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .job__title {
        font-weight: 700;
        font-size: 1.25rem;
        margin-bottom: 0.8125rem;
    }

    p {
        margin-bottom: 0.5rem;
    }

    p.countrys {
        color: $blue-color;
        font-size: 1rem;
    }

    &:hover {
        -webkit-box-shadow: 0 0 0.375rem #f0f0f0;
        box-shadow: 0 0 0.375rem #f0f0f0;
    }

    .appy-action {
        display: flex;
        justify-content: flex-end;
    }
}

.pe-20 {
    padding-right: 20px;
}

.career_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    margin-bottom: 20px;
    .section-heading{
        margin: 0;
        margin-bottom: 10px;
    }

    @media (max-width:786px) {
        flex-direction: column;
    }

    .career_filter_section {
        display: flex;
        align-items: center;
        flex-direction: row;
        margin-bottom: 20px;
        p{
            flex-shrink: 0;
            margin: 0;
            margin-right: 10px;
        }
    }
}

@media (max-width:786px) {
    .jobop__container {
        padding: 15px;
        flex-direction: column;


        .appy-action {
            margin-top: 10px;
            display: flex;
            justify-content: flex-end;
        }
    }
}