
@import "../../App.scss";

.media_page {
    .article {
        padding: 50px 0 20px;
        margin: 0;
        margin-right: 40px;
        height: 100%;
        border-bottom: 1px solid rgba(112, 112, 112, 0.3);

        .article_bradcamb {
            margin: 0;
            font-size: 14px;
            opacity: 0.6;
            font-weight: 500;
            color: black;
        }
        
        h2 {
            font-size: 18px;
            color: black;
            font-weight: 500;
        }

        .readMoreBtn {
            color: $red-color;
            margin: 0;
            font-weight: 500;
            cursor: pointer;
        }
    }

    .media_filter_section {
        display: flex;
        flex-direction: row;
        align-items: center;
        p{
            margin: 0;
            margin-right: 10px;
            height: 100%;
            vertical-align: middle;
        }
        .media_filter {
            width: 200px;
            &:focus{
                box-shadow: none;
            }
        }
    }

    @media (max-width:786px) {
        .article {
            margin-right: 0px;

            h2 {
                font-size: 18px;
            }

            .article_bradcamb {
                font-size: 12px;
            }
        }
    }
}
.details-page {
    
      img {
          width: fit-content;
          margin: 0 auto;
          display: block;
      }
  }
  @media (max-width:786px) {
    .details-page {
          padding: 10px;

          img {
              width: 100%;
          }
      }
  }
.line-text-4{
    overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 4; /* number of lines to show */
        line-clamp: 4; 
-webkit-box-orient: vertical;
}