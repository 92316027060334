.whatsapp-icon-container {
    position: fixed;
    bottom: 30px; /* Distance from the bottom of the screen */
    right: 10px; /* Distance from the right side of the screen */
    z-index: 1000; /* Ensure it appears above other elements */
}

.whatsapp-icon {
    width: 70px; /* Size of the icon */
    height: 70px;
    cursor: pointer;
}
