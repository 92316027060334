@import '../../App.scss';
.check-list{
    li{
     display: flex;   
    .icon{
        color: $blue-color;
        display: inline-block;
        margin-right: 10px;
    }
    p{
        margin-bottom: 2px;
    }
    }
}
table{
    td{
        width: 50%;
        text-align: center;
    }
}