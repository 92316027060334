$white-color: #fff;
$black-color: #080808;
$text-color: #3f3f3e;
$red-color: #EE4648;
$blue-color: #0099ff;
$orange-color: #f69557;
$green-color: #32CF6C;
$red-color-light: rgba(238, 70, 72, .2);

.error_wrapper {
    position: relative;
    width: 100%;
    height: 100vh;
    background: linear-gradient(90deg, rgba(188, 0, 0, 0.48) 0.63%, rgba(156, 0, 98, 0.48) 19.26%, rgba(131, 0, 176, 0.48) 34.27%, rgba(0, 33, 205, 0.48) 65.32%, rgba(0, 240, 255, 0.48) 95.86%),
            linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
            display: flex;
            justify-content: center;
            align-items: center;
            color: $white-color;
            h1{
                font-weight: 600;
                font-size: 200px;
                margin: 0;
            }
            p{
                letter-spacing: 20px;
                font-size: 20px;
                text-transform: uppercase;
            }
            @media (max-width:786px) {
                h1{
                    font-weight: 600;
                    font-size: 130px;
                    margin: 0;
                }
                p{
                    letter-spacing: 7px;
                    font-size: 18px;
                    text-transform: uppercase;
                }
            }
}