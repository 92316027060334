@import '../../App.scss';

.home__showreel {
    margin-top: 50px;
    position: relative;
    width: 100%;
    height: 95vh;
    background-color: rgba(12, 8, 9, .39);

    img {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
        z-index: -1;
    }

    .h-hero__form-subtext {
        min-width: 100%;
        margin-top: 24px;
        font-size: 14px;
        line-height: 20px;
        font-weight: 600;

        a {
            color: $white-color;
            text-decoration: underline;

            &:hover {
                color: $red-color;
            }
        }
    }

}

.csrimg {
    max-width: 400px;
    width: 100%;
    margin: 0 auto;
}

.desktop-reverse{
    flex-direction: row-reverse;
    @media (max-width:786px) {
        flex-direction: column-reverse;
    }
}
@media (max-width:786px) {
    .csr_landing.home__showreel .h-hero__heading-wrap {
        .h1.large {
            font-size: 30px;
            line-height: 35px !important;
        }

        p {
            font-size: 14px;
        }
    }
}


.article_section {
    padding: 10px;
    border: 1px solid #ccc;
    margin: 20px 0;

    .article_img {
        height: 280px;
        margin-right: 15px;

        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }

    .article_content {
        margin-left: 15px;

        .article_title {
            font-size: 25px;
            font-weight: 500;
            margin-bottom: 20px;
        }
    }

    .article_action_btn {
        flex-direction: row;
        align-items: center;

        .btn {
            margin: 0;
            margin-right: 10px;
        }

        .share_btn {
            font-size: 25px;
            color: $red-color;
            cursor: pointer;
        }
    }
}

@media (max-width:786px) {
    .article_section {
        padding: 15px;
        border: 1px solid #ccc;
        margin: 20px 0;

        .article_img {
            margin-right: 0px;
            margin-bottom: 20px;

            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }

        .article_content {
            margin-left: 0px;

            .article_title {
                font-size: 25px;
                font-weight: 500;
                margin-bottom: 10px;
            }
        }

        .article_action_btn {
            flex-direction: row;
            align-items: center;

            .btn {
                margin: 0;
                margin-right: 10px;
            }

            .share_btn {
                font-size: 25px;
                color: $red-color;
                cursor: pointer;
            }
        }
    }
}