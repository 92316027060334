$white-color: #fff;
$black-color: #080808;
$text-color: #3f3f3e;
$red-color: #EE4648;
$blue-color: #42bdec;
$orange-color: #f69557;
$green-color: #40b96c;
$red-color-light: rgba(238, 70, 72, .2);

footer {
    background: #3a3a3a;

    .footer-sec {
        display: flex;
        justify-content: space-between;
        flex-direction: row;

        .footer-link ul {
            display: flex;
            flex-direction: row;

            li {
                margin-right: 20px;
                @media (max-width:786px) {
                    margin-right: 8px;
                }
            }
        }
    }

    hr {
        color: rgba(255, 255, 255, .8);
    }

    .mid {
        margin-bottom: 20px;

        li {
            display: flex;
            align-items: center;
            color: rgba(255, 255, 255, .8);
        }

        a {
            color: $white-color;
            font-size: 14px;
        }
    }

    .top {
        li {
            color: rgba(255, 255, 255, .8);
        }

        a {
            color: $white-color;
            font-size: 14px;
        }

        .SocialList {

            .icons {
                color: $white-color;
                border: 1.5px solid $white-color;

                &:hover {
                    border: 0px solid;
                }
            }
        }
    }

    a,
    p {
        font-size: 14px;
        font-weight: 300;
        color: rgba(255, 255, 255, .5);
    }
    
}

@media (max-width:1048px) {
    footer {
        .footer-sec {
            flex-direction: column;
            align-items: center;
            
            p {
                font-size: 12px;
                margin: 10px 0;
                text-align: center;
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: center;

                span {
                    display: block;
                }
            }
        }
    }

    footer .footer-sec .footer-link ul {
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
    }

    .SocialList {
        margin-bottom: 20px;
    }
}