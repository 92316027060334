@import '../../App.scss';

.media-kit {
    .col-12 {
        display: flex;
        justify-content: center;

        .btn {
            padding: 20px 30px;
            color: $black-color;
            font-weight: 500;
            font-size: 20px;
            background-color: #e6e6e6;
            width: 250px;

        }

        &:nth-child(1) {
            .btn:hover {
                background-color: $orange-color;
            }
        }

        &:nth-child(2) {
            .btn:hover {
                background-color: $blue-color;
            }
        }

        &:nth-child(3) {
            .btn:hover {
                background-color: $green-color;
            }
        }
    }

}