@import "../../App.scss";


a {
    text-decoration: none;

    &:hover {
        color: $blue-color;
    }
}

.p-relative {
    position: relative;
}

ul {
    list-style: none;
    padding: 0;
}
header .navbar .navbar-brand img {
    width: 100%;
    max-width: 250px;
}


header {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 999;
    background: $white-color;
    box-shadow: 0 0 0.1875rem #b7b7b7;

    
   
    .navbar {
        padding: 0;

        .container-fluid {
            position: relative;
            padding: 0 60px;

            @media (max-width:1400px) {
                padding: 0 40px;
            }
            @media (max-width:786px) {
                padding: 0 20px;
            }
        }

        .navbar-brand {
            // width: 180px;
        }

        @media (min-width:960px) and  (max-width:1400px){

            .mobile-nav { display: inline-block;
            visibility: visible;}
            
            
        }
        .mobile-nav {
            display: none;
            visibility: hidden;
            flex-direction: column;
            justify-content: space-between;
            width: 28px;
            height: 22px;
            margin-top: 0;
            transition: .5s ease-in-out;
            cursor: pointer;
            z-index: 99999;

            span {
                width: 100%;
                height: 2.7px;
                border-radius: 2px;
                background-color: $black-color;
                transition: .25s ease-in-out;
            }

            &.active {
                span {
                    &:nth-child(1) {
                        transform: translate(7px, 10px) rotate(45deg);
                    }

                    &:nth-child(2) {
                        opacity: 0;
                    }

                    &:nth-child(3) {
                        transform: translate(7px, -8px) rotate(-45deg);
                    }
                }
            }

        }

        .nav-item {
            margin: 0 5px;
            padding: 0;

            // font-weight: 400;
            button.nav-link {
                padding: 8px;
                background: transparent;
                border: none;
                outline: none;
            }

            .nav-link {
                color: $black-color;
                letter-spacing: .2px;
                transition: .1s ease-in-out;
                font-size: 15px;
                font-weight: 400;

                .icon {
                    display: none;
                }

                @media (max-width:1300px) {
                }


            }


            .nav-link {
                &.hover {
                    color: $blue-color;
                }
            }


            .dropdown-menu {
                width: 210px;
                border: none;
                top: 40px;
                padding: 10px 0;
                border-radius: 0;
                box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;

                .nav-item {
                    padding: 0 10px;
                    position: relative;
                }

                .nav-link {
                    display: flex;
                    align-items: center;
                    flex-direction: row;
                    justify-content: normal;
                    transition: .2s ease-in-out;

                    .icon {
                        position: absolute;
                        left: 0px;
                        overflow: hidden;
                        width: 0px;
                        display: inline-block;
                    }

                    &:hover {
                        padding-left: 10px;

                        .icon {
                            width: 15px;
                        }
                    }
                }

                &.dropdown-menu-v2 {
                    width: fit-content;
                    top: 40px;
                    padding-top: 0;
                    padding-bottom: 0;
                    padding-right: 1rem;
                    padding: 10px;

                    @media (max-width:1300px) {
                        width: calc(100% - 40px);
                        // left: 20px;
                        padding: 0 20px;
                    }


                    .col-2 {
                        display: flex;
                        width: 100%;
                        flex-direction: row;
                        margin-top: 10px;
                        padding: 10px 10px 20px;

                        li {
                            // width: 210px;
                            line-height: 25px;

                        }

                        .innovation-wrapper ul.grid-2 {
                            display: grid;
                            grid-template-columns: repeat(2, 1fr);
                            grid-column-gap: 1rem;
                        }

                        .innovation-wrapper:not(:last-of-type) {
                            margin-right: 1.25rem;
                            padding-right: 1rem;
                            border-right: 1px solid #d1d1d1;
                        }

                        .innovation-wrapper {
                            ul {
                                display: flex;
                                flex-direction: column;
                            }

                            &.single_col {
                                ul {
                                    display: flex;
                                    flex-direction: column;

                                    li {
                                        margin-bottom: 0px !important;
                                        padding-bottom: 0px !important;

                                        a {
                                            margin-bottom: 0px !important;
                                            padding-bottom: 0px !important;
                                        }
                                    }
                                }
                            }
                        }

                        .innovation-wrapper .title-wrapper {
                            text-align: left;
                            padding-bottom: 10px;

                            h2 {
                                font-size: 16px;
                                font-weight: 500;
                                padding-bottom: 0.5rem;
                                margin-bottom: 0.5rem;
                                border-bottom: 1.5px solid $red-color;
                                display: inline-block;
                                color: $black-color;
                            }
                        }

                        h3 {
                            line-height: 1.2rem;
                            font-weight: 400;
                            font-size: .9rem;
                            margin: 0;
                            width: 200px;
                            display: flex;
                            align-items: center;

                            .icon {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                transition: .3s ease-in-out;
                                display: inline-block;
                                overflow: hidden;
                                width: 0px;
                                padding: 0;
                            }

                        }

                        a:hover {
                            h3 {
                                .icon {
                                    width: 15px;
                                }
                            }
                        }
                    }

                    &.center_dropdown {
                        left: 50%;
                        transform: translate(-50%, 0);
                    }
                }

                &.break {
                    .col-2 .innovation-wrapper>ul {
                        display: grid;
                        grid-template-columns: repeat(2, 1fr);
                        grid-column-gap: 1rem;

                        li {
                            max-width: 250px;
                            width: 200px;
                        }
                    }

                    &.grid-3 .col-2 .innovation-wrapper>ul {
                        grid-template-columns: repeat(3, 1fr);
                        grid-column-gap: .8rem;
                    }
                }

                li {
                    padding: 2px 0px;
                }

            }

            li {

                a {
                    color: $black-color;
                    transition: .1s ease-in-out;

                    p {
                        color: $text-color;
                        line-height: 18px;
                        font-size: 13px;
                        letter-spacing: -.2px;
                    }

                    &:hover {
                        color: $blue-color;


                        p {
                            color: $black-color;
                        }
                    }
                }




            }

            .mega-drop-v1 {
                color: #000;
                font-weight: normal;
                transition: .3s ease-in-out;
                display: flex;
                flex-direction: column !important;
                align-items: flex-start !important;
                p {
                    color: $text-color;
                    font-size: 12px;
                    font-weight: 300;
                }

                &:hover {
                    color: $blue-color;

                    h3 {
                        .icon {
                            width: 2px;
                        }
                    }

                    p {
                        color: $black-color;
                    }
                }
            }

            .nav-item {
                font-weight: 400;

                .nav-link {
                    padding: 3px;
                    font-size: 15px;

                }
            }
        }

        .dropdown-submenu {
            top: 0;
            left: 180px;
        }

        .dropdown-megaMenu .nav-link,
        .dropdown .nav-link {
            width: 100% !important;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            .nav-icon {
                // display: block;
                font-size: 14px;
                transition: .2s ease-in-out;

                &.hover {
                    transform: rotate(180deg);
                    transform-origin: center;
                }

                @media (max-width:786px) {
                    display: block;
                }
            }
        }
    }

    .search {
        position: fixed;
        z-index: 1000;
        width: 100%;
        top: 0;
        left: 0;
        overflow: hidden;
        text-align: center;
        height: 100vh;
        pointer-events: none;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            pointer-events: none;
            background: rgba(0, 0, 0, 0.3);
            opacity: 0;
            transition: opacity 0.6s;
            transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
        }

        &.search--open {
            pointer-events: auto;
            /* display: block !important; */
            pointer-events: auto;
            opacity: 1;
            visibility: visible;

            .search__inner.search__inner {
                transform: translate3d(0, 0, 0);
            }

            &::before {
                opacity: 1;
            }

            .btn--search-close {
                display: block;
            }
        }

        .search__inner {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 50%;
            background: #f0f0f0;
            transition: transform 0.6s;
            transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);

            &.search__inner--up {
                color: #333333;
                background: #ffffff;
                transform: translate3d(0, -100%, 0);
            }

            &.search__inner--down {
                top: 50%;
                color: #f8f8f8;
                background: #f8f8f8;
                transform: translate3d(0, 100%, 0);
            }

            .search__form {
                width: 75%;
                max-width: 900px;

                .search__input {
                    font-size: 4vw;
                    line-height: 1;
                    display: inline-block;
                    box-sizing: border-box;
                    width: 100%;
                    padding: 0 0 0.1em 0;
                    border: 0;
                    background: transparent;
                    border-radius: 0;
                    font-weight: 200;
                    border: none;
                    outline: none;
                    border-bottom: 4px solid !important;
                }

                .search__info {
                    font-size: 90%;
                    font-weight: 700;
                    display: block;
                    width: 100%;
                    margin: 0 auto;
                    padding: 0.85em 0;
                    text-align: right;
                }
            }
        }

        .btn--search-close {
            position: absolute;
            z-index: 100;
            top: 1.25em;
            right: 1.25em;
            display: none;
            background-color: #000;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            border-radius: 50%;
            width: 50px;
            height: 50px;
            padding: 8px;
            color: #FFFFFF !important;

            svg {
                width: 100%;
                height: 100%;
            }
        }
    }
}


// @media (min-width:1335px) and  (max-width:1400px){

//     header .navbar .navbar-brand img {
//         width: 100%;
//         max-width: 160px;
//     } 
// }

// @media (min-width:1334px) and  (max-width:1065px){

//     header .navbar .navbar-brand img {
//         width: 100%;
//         max-width: 165px;
//     }
// }

@media screen and (max-width: 1400px) {

    header .navbar .navbar-brand img {
        width: 100%;
        max-width: 164px;
    }
}



.header-social {
    max-width: 200px;
    width: 100%;
    float: right;
}

.search-feild {
    padding-right: 20px;
    margin: 0 20px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;

    &::after {
        content: '';
        display: block;
        background: $text-color;
        width: 1px;
        height: 30px;
        position: absolute;
        top: 60%;
        transform: translate(-50%, -50%);
        right: 0;
    }

    .search-btn,
    button {
        width: 35px;
        height: 35px;
        font-size: 25px;
        background: transparent;
        border: none;
        display: flex;
        justify-content: center;
        color: $black-color;
        align-items: center;
    }
}


// @media (max-width:960px) {


//     header {
//         .search-feild {
//             padding-right: 0;
//             margin-right: 0;

//             &::after {
//                 display: none;
//             }

//         }

//         .header-social {
//             display: none;
//         }

//         .navbar {
//             .container-fluid {
//                 padding: 0 16px;
//             }

//             .navbar-brand {
//                 position: unset;
//             }

//             .mobile-nav {
//                 display: flex;
//                 visibility: visible;
//             }

//             .mobileNav {
//                 opacity: 0;
//                 visibility: hidden;
//                 width: 100%;
//                 height: 100vh;
//                 overflow: hidden;
//                 overflow-y: auto;
//                 padding: 65px 20px 15px;
//                 position: fixed;
//                 top: 0;
//                 right: -100%;
//                 border-top: 0;
//                 background: #fff;
//                 border-radius: 0;
//                 z-index: 9999;
//                 box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
//                 -webkit-transition: all .3s ease-in;
//                 -moz-transition: all .3s ease-in;
//                 -ms-transition: all .3s ease-in;
//                 -o-transition: all .3s ease-in;
//                 transition: all .3s ease-in;

//                 &.active {
//                     right: 0;
//                     opacity: 1;
//                     visibility: visible;
//                 }

//                 .navbar-nav {
//                     height: 100%;
//                     overflow-y: scroll;
//                 }

//                 .dropdown-menu {
//                     padding: 0;
//                     width: 100%;
//                     background: #f8fdff;
//                     box-shadow: none;
//                     height: 0px;
//                     overflow: hidden;
//                     transition: .3s height ease-in-out;
//                     display: block;
//                     &.show{
//                         overflow: auto;
//                         height: auto;
//                     }
//                     .nav-item {
//                         padding-top: 10px;
//                         background: unset;
//                         &:last-child{
//                             padding-bottom: 10px;
//                         }
//                         p{
//                             margin-bottom: 0;
//                         }
//                     }
//                 }

//                 .nav-item {
//                     background: #fff;
//                     .dropdown-menu.dropdown-menu-v2 {
//                         width: 100% !important;
//                         padding: 0 10px;

//                         &.center_dropdown {
//                             transform: unset;
//                         }

//                         .col-2 {
//                             flex-direction: column !important;

//                             h3 {
//                                 line-height: 1.3rem;
//                             }

//                             .title-wrapper {
//                                 text-align: left !important;
//                                 padding-left: 0;
//                             }

//                             ul {
//                                 display: flex !important;
//                                 flex-direction: column;

//                                 li {
//                                     // padding-le ft: 0;
//                                     padding: 0;
//                                 }

//                             }

//                             .col-1 {
//                                 width: 100%;
//                             }
//                         }
//                     }
//                 }

//             }
//             .nav-item{
//                 margin: 0;
//                 .dropdown-menu .nav-link{
//                     justify-content: space-between;
//                     &:hover{
//                         padding-left: 0px;
//                     }
//                 }
//             }
//         }

//     }
// }



//sunil changes made on 17-1-24

@media (min-width:1048px) and (max-width: 1334px){
    .search-feild{
        display: none;
    }

    .header-social{
        display: none;
    }
}

@media (min-width:1024px) and (max-width: 1048px){
   
    .navbar-nav{
        display: block;
    }
}







@media (max-width:1048px) {


    header {
        .search-feild {
            padding-right: 0;
            margin-right: 0;

            &::after {
                display: none;
            }

        }

        .header-social {
            display: none;
        }

        .navbar {
            .container-fluid {
                padding: 0 16px;
            }

            .navbar-brand {
                position: unset;
            }

            .mobile-nav {
                display: flex;
                visibility: visible;
            }

            .mobileNav {
                opacity: 0;
                visibility: hidden;
                width: 100%;
                height: 100vh;
                overflow: hidden;
                overflow-y: auto;
                padding: 65px 20px 15px;
                position: fixed;
                top: 0;
                right: -100%;
                border-top: 0;
                background: #fff;
                border-radius: 0;
                z-index: 9999;
                box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
                -webkit-transition: all .3s ease-in;
                -moz-transition: all .3s ease-in;
                -ms-transition: all .3s ease-in;
                -o-transition: all .3s ease-in;
                transition: all .3s ease-in;

                &.active {
                    right: 0;
                    opacity: 1;
                    visibility: visible;
                }

                .navbar-nav {
                    height: 100%;
                    overflow-y: scroll;
                }

                .dropdown-menu {
                    padding: 0;
                    width: 100%;
                    background: #f8fdff;
                    box-shadow: none;
                    height: 0px;
                    overflow: hidden;
                    transition: .3s height ease-in-out;
                    display: block;
                    &.show{
                        overflow: auto;
                        height: auto;
                    }
                    .nav-item {
                        padding-top: 10px;
                        background: unset;
                        &:last-child{
                            padding-bottom: 10px;
                        }
                        p{
                            margin-bottom: 0;
                        }
                    }
                }

                .nav-item {
                    background: #fff;
                    .dropdown-menu.dropdown-menu-v2 {
                        width: 100% !important;
                        padding: 0 10px;

                        &.center_dropdown {
                            transform: unset;
                        }

                        .col-2 {
                            flex-direction: column !important;

                            h3 {
                                line-height: 1.3rem;
                            }

                            .title-wrapper {
                                text-align: left !important;
                                padding-left: 0;
                            }

                            ul {
                                display: flex !important;
                                flex-direction: column;

                                li {
                                    // padding-le ft: 0;
                                    padding: 0;
                                }

                            }

                            .col-1 {
                                width: 100%;
                            }
                        }
                    }
                }

            }
            .nav-item{
                margin: 0;
                .dropdown-menu .nav-link{
                    justify-content: space-between;
                    &:hover{
                        padding-left: 0px;
                    }
                }
            }
        }

    }
}


.search-feid {
    position: absolute;
    top: 0;
    width: 100%;
    background: $white-color;
    padding: 10px 100px;
    transition: .2s ease-in-out;
    opacity: 0;
    visibility: hidden;
    height: 100%;
    display: flex;
    align-items: center;

    .d-flex {
        width: 100%;
    }

    &.search--open {
        top: 0;
        opacity: 1;
        visibility: visible;
    }

    .form-group {
        display: flex;
        flex-direction: row;
        align-items: center;
        flex: 1 1 auto;
    }

    input {
        padding: 0 10px;
        border: none;
        outline: none;
        width: 100%;
        display: inline-block;
    }

    button {
        background: transparent;
        border: none;
        outline: none;
        color: #747474;
        font-size: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

@media (max-width:786px) {
    .search-feid {
        padding: 10px;
        font-size: 14px;

        input {
            &:placeholder-shown {
                font-size: 14px;
            }
        }
    }
}


@media (max-width:786px) {
    header .navbar .nav-item{
        .nav-link,
        button.nav-link,
        button.nav-li{
            // font-weight: 300;
            padding-left: 0;
            padding-right: 0;
        }
    }
}


.mobile_menu{
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: #fff;
    padding: 20px;
    padding-top: 55px;
    
    &.active{
        display: block;
    }
    
    .nav-item {
        border-bottom: 1px solid #ffeeef;
        button{
            display: flex;
            justify-content: space-between;
            border: none;
            width: 100%;
            background: transparent;
            .nav-icon {
                transform: rotate(-90deg);
            }
        }
        .icon{
            display: none;
        }
        & >.mobile_menu{
            padding-top: 22px;
            .backBtn{
                padding-bottom: 15px;
            }
        }
    }
}

//sunil

@media (min-width:992px) and (max-width:1024px){
    .mobileNav .navbar-nav{
        flex-direction:column;
    }
    
}
@media (min-width:1024px) and (max-width: 1048px){
   
    .mobileNav .navbar-nav{
        display: block;
    }
}
