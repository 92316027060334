$white-color: #fff;
$black-color: #080808;
$text-color: #3f3f3e;
$red-color: #EE4648;
$blue-color: #42bdec;
$orange-color: #f69557;
$green-color: #40b96c;
$red-color-light: rgba(238, 70, 72, .2);

.search-page {
    background: rgba(238, 70, 72, .8);

    .h-hero__heading-wrap {
        max-width: 1000px;
        width: 100%;
        margin-bottom: 35px;

        .h1.large {
            font-size: 70px;
            line-height: 70px;
        }
    }

    .form-group {
        max-width: 800px;
        width: 100%;
        margin: 0 auto;

        .input-group {
            background: $white-color;
            padding: 10px 30px;
            display: flex;
            align-items: center;
            flex-direction: row;

            input {
                padding: 0;
                border: none;
                outline: none;
            }

            .pointer {
                color: $black-color;
                font-size: 20px;
            }
        }
    }
}



    .article {
        padding: 50px 0 20px;
        margin: 0;
        margin-right: 40px;
        border-bottom: 1px solid rgba(112, 112, 112, 0.3);

        .article_bradcamb {
            margin: 0;
            font-size: 14px;
            opacity: 0.6;
            font-weight: 500;
            color: black;
        }

        h2 {
            font-size: 20px;
            font-weight: 500;
        }

        .readMoreBtn {
            color: $red-color;
            margin: 0;
            font-weight: 500;
            cursor: pointer;
        }
    }

    .media_filter_section {
        display: flex;
        flex-direction: row;
        align-items: center;
        p{
            margin: 0;
            margin-right: 10px;
            height: 100%;
            vertical-align: middle;
        }
        .media_filter {
            width: 200px;
            &:focus{
                box-shadow: none;
            }
        }
    }

    @media (max-width:786px) {
        .article {
            margin-right: 0px;

            h2 {
                font-size: 18px;
            }

            .article_bradcamb {
                font-size: 12px;
            }
        }
    }
