$white-color: #fff;
$black-color: #080808;

.SocialList {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 200px;
    width: 100%;

    .icons,
    a.icons {
        cursor: pointer;
        width: 28px;
        height: 28px;
        border: 1.5px solid $black-color;
        color: $black-color;
        border-radius: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 14px;
        transition: .3s ease-in-out;

        &:hover {
            border: 0px solid;
            color: $white-color;

            &:nth-child(1) {
                background: #3b5998;
            }

            &:nth-child(2) {
                background: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
            }

            &:nth-child(3) {
                background: #00acee;
            }

            &:nth-child(4) {
                background: #c4302b;
            }

            &:nth-child(5) {
                background: #0A66C2;
            }

            &:nth-child(6) {
                background: #128C7E;
            }

        }
    }
}

.SocialList a.icons img {
    width: 100%;
    padding: 5px;

   
}
footer .SocialList a.icons img{
    -webkit-filter: brightness(0) invert(1) !important;
    filter: brightness(0) invert(1) !important;
}