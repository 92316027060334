

  .popup-onload {
    position: fixed;
    bottom: 10px;
    left: 10px;
    /* transform: translate(-50%, -50%); */
    background: #ffffff;
    padding: 20px;
    box-shadow: 0px 0px 10px rgb(0 0 0 / 54%);
    z-index: 1000;
    /* display: none; */
    width: 100%;
    max-width: 600px;
    border-radius: 10px;
    box-shadow: 0 8px 28px rgba(0, 0, 0, .28);
    bottom: 0px;
    left: 50%;
    transform: translate(-50%, -5%);
}
.popup-onload h4 {
    margin-bottom: 16px;
}
.popup-onload p {
    font-size: 14px;
}
.cookie-accept {
    text-align: right;
}
.cookie-accept button {
    background-color: #EE4648;
    border: 1px solid #EE4648;
    color: #fff;
    border-radius: 6px;
    padding: 3px 10px;
    transition: all ease .2s;
}

.cookie-accept button:hover {
    background-color: #fff;
    color: #EE4648;
}

  