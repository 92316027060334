$white-color: #fff;
$black-color: #080808;
$text-color: #3f3f3e;
$red-color: #EE4648;
$blue-color: #42bdec;
$orange-color: #f69557;
$green-color: #40b96c;
$red-color-light: rgba(238, 70, 72, .2);

.team-container {
    margin-bottom: 20px;
}

.team {
    padding: 0 30px;

    .business-img {
        display: block;
        margin: 0 auto;
        height: 200px;
        width: 200px;
        border-radius: 100px;
        overflow: hidden;
    }

    .business-text {
        padding: 15px 5px;
        background: transparent;
        text-align: center;

        h4 {
            font-size: 18px;
            margin: 0;
        }

        p {
            color: $red-color;
            font-size: 15px;
            margin-bottom: 8px;
            text-align: center;


            &.rightArrowBtn {
                color: $black-color;
            }
        }
    }
}

.teamModal-container {
    position: absolute;
    transform: translateX(-100%);
    display: block;
    visibility: hidden;
    top: -10px;
    opacity: 0;
    height: 350px;
    transition: .8s ease-in-out;

    .modal-dialog {
        max-width: 1300px;
        margin-bottom: 0;
        margin-top: 0px;
        top: 0;
        display: block;

        .modal-content {
            position: relative;
            height: 330px;
            overflow: hidden;
            border-radius: 0px;

            .teamModal {
                position: relative;
                display: flex;
                flex-direction: row;
                padding: 0;

                .team-name {
                    margin-bottom: 0;
                }

                // height: 500px;
                .des {
                    color: $red-color;
                    font-weight: 500;
                }

                .teamProfile {
                    position: relative;
                    margin-right: 30px;

                    h4 {
                        font-size: 22px
                    }

                    p {
                        font-size: 14px;
                    }

                    .d-flex {
                        justify-content: space-between;

                        .linkdicon {
                            font-size: 20px
                        }
                    }
                }

                .team-img {
                    display: block;
                    width: 220px;
                    margin-bottom: 10px;

                    img {
                        width: 100%;
                    }
                }



                .team-content {
                    .d-flex {
                        align-items: center;
                        margin-bottom: 10px;
                    }

                    .social-icon {
                        margin-left: 30px;
                        position: relative;
                        top: unset;
                        right: unset;
                    }

                    .logo-icon {
                        // margin: 10px 0 20px;
                        width: 30px;
                    }

                    .dots-icon {
                        padding-left: 5px;
                        width: 25px;
                    }
                }

                .para {
                    overflow-y: scroll;
                    font-size: 16px;
                    // box-sizing: content-box;

                    &::-webkit-scrollbar-track {
                        padding: 2px 0;
                        background-color: $red-color-light;
                    }

                    &::-webkit-scrollbar-thumb {
                        border-radius: 10px;
                        background-color: $red-color;
                    }

                    &::-webkit-scrollbar {
                        width: 5px;
                    }

                    height: 200px;
                }
            }

            .btn-close {
                position: absolute;
                z-index: 99;
                right: 20px;
                top: 20px;
                cursor: pointer;
                display: block;
            }

            &::after {
                content: '';
                display: block;
                position: absolute;
                bottom: 0;
                left: 0;
                width: 100%;
                height: 3px;
                background: $blue-color;
            }
        }
    }

    &.active {
        transform: translateX(0);
        visibility: visible;
        opacity: 1;
    }

    .news-content {
        overflow-y: scroll;

    }
}

@media (max-width:786px) {
    .team-sec .col-12 {
        position: relative;
    }

    .teamModal-container {
        position: relative;
        height: auto;
        overflow: unset;
        display: none;

        &.active {
            display: block;
        }

        .modal-dialog {
            position: unset;
            overflow: unset;
            margin: 0;
            
            .modal-content {
                margin: 0;
                position: unset;
                height: 100%;
                border: none;
                overflow: unset;

                .teamModal {
                    flex-direction: column;

                    .teamProfile {
                        display: none;
                    }

                    .para {
                        height: 100%;
                    }
                }

                &::after {
                    display: none;
                }
            }
        }
    }
}


.team.businessCard {
    padding-bottom: 20px;

    .rightArrowBtn {
        cursor: pointer;
    }
}

.langTooltips {
    position: absolute;
    top: 0;
    left: 200px;
    background: $text-color;
    padding: 20px;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    opacity: .9;
    user-select: none;

    &:before {
        border-top: 10px solid transparent;
        border-left: 10px solid $text-color;
        border-right: 10px solid transparent;
        content: '';
        position: absolute;
        top: 50%;
        left: -10px;
        // @include transform(translate(-50%, 0%));
    }
}

hr {
    margin: 0;
}