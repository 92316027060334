@import "../../../App.scss";

.media_page {
    .article {
        padding: 50px 0 20px;
        margin: 0;
        margin-right: 40px;
        border-bottom: 1px solid rgba(112, 112, 112, 0.3);

        .article_bradcamb {
            margin: 0;
            font-size: 14px;
            opacity: 0.6;
            font-weight: 500;
            color: black;
        }

        h2 {
            font-size: 20px;
            font-weight: 500;
        }

        .readMoreBtn {
            color: $red-color;
            margin: 0;
            font-weight: 500;
            cursor: pointer;
        }
    }

    .media_filter_section {
        display: flex;
        flex-direction: row;
        align-items: center;
        p{
            margin: 0;
            margin-right: 10px;
            height: 100%;
            vertical-align: middle;
        }
        .media_filter {
            width: 200px;
            &:focus{
                box-shadow: none;
            }
        }
    }

    @media (max-width:786px) {
        .article {
            margin-right: 0px;

            h2 {
                font-size: 18px;
            }

            .article_bradcamb {
                font-size: 12px;
            }
        }
    }
}