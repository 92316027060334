@import "../../App.scss";

.product_card {
    padding: 10px;
    padding-right: 30px;
    padding-left: 30px;
    margin-top: 30px;
    @media (max-width:786px) {
        padding: 20px;
        padding-top: 10px;
        margin-top: 0px;
    }

    h5 {
        color: $black-color;
    }

    p {
        text-align: left;
        font-size: 16px;
        color: #4E5054;
        font-weight: 300;
    }

    .label-btn {
        font-weight: 500;
        color: $orange-color;
    }
}

.brandLogoRow {
    display: grid;
    grid-auto-flow: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    .logo-img {
        margin: 0 20px;
        width: 140px;
    }
    @media (max-width:786px) {
        grid-auto-flow: unset;
        grid-template-columns: repeat(auto-fill, minmax(100px, 140px));

        .logo-img{
            // overflow: auto;
            width: 100px;
            margin: 0 10px;
        }
    }
}

.feat_card {
    // padding: 15px !important;

    .feat_icon {
        width: 100px;
        margin-bottom: 20px;
        color: $white-color;
    }

    h5 {
        font-size: 16px;
        margin-bottom: 10px !important;
    }

    p {
        font-size: 14px;
        font-weight: 300;
    }
}

.solution_feature_card {
    position: relative;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;

    .card-body {
        padding: 30px;
        border-radius: .5rem;
        background: $blue-color;
        color: $white-color;

        p {
            &:last-child {
                margin: 0;
            }
        }
    }
}

@media (max-width:786px) {
    .customer-sec .row{
        padding-top: 0px;
    }
}