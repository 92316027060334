@import "../../App.scss";

.request_banner {
    margin: 20px auto;
    background: $blue-color;
    color: $white-color;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: .5rem;

    p {
        margin-bottom: 20px;
    }

    .section-heading {
        color: $white-color;
    }

    .btn {
        margin-top: 1rem;
    }

    @media (max-width:786px) {
        border-radius: 0px;
        margin-bottom: 0;
    }
}