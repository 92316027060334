@import "../../App.scss";
.ourvalue_container {
    .icon {
        width: 150px;

        svg {
            fill: $blue-color;

        }
    }

    h5 {
        font-size: 30px;
        font-weight: 600;
        color: $red-color;
        margin-bottom: 20px;
    }
}

.valueContainer {
    margin-top: 100px;

    &:nth-child(6) .val__wrapper,
    &:nth-child(1) .val__wrapper {
        background: $red-color;

        .icon {
            border: 10px solid $red-color;

            svg {
                fill: $red-color;
            }
        }
    }

    &:nth-child(7) .val__wrapper,
    &:nth-child(2) .val__wrapper {
        background: $orange-color;

        .icon {
            border: 10px solid $orange-color;

            svg {
                fill: $orange-color;
            }
        }
    }

    &:nth-child(3) .val__wrapper {
        background: $green-color;

        .icon {
            border: 10px solid $green-color;

            svg {
                fill: $green-color;
            }
        }
    }

    &:nth-child(4) .val__wrapper {
        background: $blue-color;

        .icon {
            border: 10px solid $blue-color;

            svg {
                fill: $blue-color;
            }
        }
    }
}
@media (max-width:786px) {
    .ourvalue_container {
        .icon{
            margin: 0 auto;
        }
            h4,p{
        text-align: center;
    }}
}

.val__wrapper {
    border-radius:.5rem;
    padding: 20px !important;
    transition: .2s ease-in-out;
    height: 100%;
    margin-bottom: 0px;
    color: $white-color;
    .top_icon{
        position: relative;
        height: 60px;
        width: 100%;
    }

    ul {
        padding-top: 5px;
        padding-left: 15px;
        font-size: 14px;
        list-style: disc;

        li {
            padding-bottom: 5px;
        }
    }

    .icon {
        position: absolute;
        top: -50%;
        left: 50%;
        border-radius: 50%;
        background: $white-color;
        transform: translate(-50%, -50%);
        font-size: 3rem;
        color: $blue-color;
        transform-origin: center;
        margin-bottom: 10px;
        transition: .2s ease-in-out;
        height: 150px;
        width: 150px;
        display: flex;
        justify-content: center;
        align-items: center;



        svg {
            transition: .2s ease-in-out;
            fill: $black-color;
        }

    }

    .val__title {
        font-size: 1.2rem;
        font-weight: 500;
        text-align: center;
    }

    &:hover {
        background: $orange-color;
        color: $white-color;
        // border: 1px solid $red-color;

        .icon {
            // transform: scale(1.2);
            color: $white-color;

            svg {
                fill: $white-color;
            }
        }
    }
}
