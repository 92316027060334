// $white-color: #fff;
// $black-color: #080808;
// $text-color: #3f3f3e;
// $red-color: #EE4648;
// $blue-color: #0099ff;
// $orange-color: #f69557;
// $green-color: #32CF6C;
// $red-color-light: rgba(238, 70, 72, .2);

// .home__showreel {
//     position: relative;
//     width: 100%;
//     height: 100vh;
//     background-color: rgba(12, 8, 9, .39);

//     img {
//         position: absolute;
//         top: 0;
//         left: 0;
//         height: 100%;
//         width: 100%;
//         object-fit: cover;
//         z-index: -1;
//     }




//     .h-hero__form-subtext {
//         min-width: 100%;
//         margin-top: 24px;
//         font-size: 14px;
//         line-height: 20px;
//         font-weight: 600;

//         a {
//             color: $white-color;
//             text-decoration: underline;

//             &:hover {
//                 color: $red-color;
//             }
//         }
//     }

// }


// .fact.about-page {
//     padding: 10px 0;

//     .section-heading {
//         font-size: 25px;
//         max-width: 800px;
//         margin: 0 auto;
//     }

//     .facts_fig.about-fact {
//         position: relative;
//     }

//     .facts_f {
//         padding: 20px;
//         width: 280px;
//         margin: 0 auto;
//         text-align: center;
//         @media (max-width:786px) {
//             width: 100%;
//         }
//     }
// }

// .about-center{
//     display: flex;
//     flex-direction: column;
//     align-items: center;
// }

$white-color: #fff;
$black-color: #080808;
$text-color: #3f3f3e;
$red-color: #EE4648;
$blue-color: #0099ff;
$orange-color: #f69557;
$green-color: #32CF6C;
$red-color-light: rgba(238, 70, 72, .2);

.home__showreel {
    position: relative;
    width: 100%;
    height: 100vh;
    background-color: rgba(12, 8, 9, .39);

    img {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
        z-index: -1;
    }




    .h-hero__form-subtext {
        min-width: 100%;
        margin-top: 24px;
        font-size: 14px;
        line-height: 20px;
        font-weight: 600;

        a {
            color: $white-color;
            text-decoration: underline;

            &:hover {
                color: $red-color;
            }
        }
    }

}


.fact.about-page {
    padding: 10px 0;

    .section-heading {
        font-size: 25px;
        max-width: 800px;
        margin: 0 auto;
    }

    .facts_fig.about-fact {
        position: relative;
    }

    .facts_f {
        padding: 20px;
        width: 280px;
        margin: 0 auto;
        text-align: center;
        @media (max-width:786px) {
            width: 100%;
        }
    }
}

.about-center{
    display: flex;
    flex-direction: column;
    align-items: center;
}