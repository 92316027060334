$white-color: #fff;
$black-color: #080808;
$text-color: #3f3f3e;
$red-color: #EE4648;
$blue-color: #0099ff;
$orange-color: #f69557;
$green-color: #32CF6C;
$red-color-light: rgba(238, 70, 72, .2);


*,
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

*,
:after,
:before {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}
// By Kuldeep

// .container {
//   padding-top: 50px;
//   padding-bottom: 50px;
// }
//Sunil
.container {
  padding-top: 50px;
  padding-bottom: 50px;
}

.inline {
  display: inline;
}

.form-select {
  border-radius: 2px;
  font-weight: 300;
  font-size: 16px;

  &:focus {
    box-shadow: none !important;
  }
}
@media (max-width:786px) {
  .container,
    .container-fluid,
    .container-lg,
    .container-md,
    .container-sm,
    .container-xl,
    .container-xxl{
      --bs-gutter-x: 2rem;
    }
}
.form-select{
      padding: 10px 20px;
}
/* Chrome, Safari, Edge, Opera */
input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}
@media (min-width:786px) {
  .text-md-left{
    text-align: left  !important;
  }
}
///All Input Feidl Design 
.form-group label.required:after {
  content: "*";
  color: #ee3b2d;
  margin-left: 0.3125rem;
}
.btn-loader {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.form-group label {
  color: #3f3f3e;
  font-size: .875rem;
  font-family: Muli, sans-serif;
  font-weight: 400;
  padding: 0px !important;
  line-height: 1.125rem;
}

.icon80 {
  padding: 15px;
  width: 110px !important;
}

.fs14 {
  font-size: 14px !important;
}

.form-group .form-control {
  border: 1px solid #ccc;
  background: transparent;
  padding: 10px 20px;
  font-size: 1rem;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 0;
  margin: 5px 0 10px;
}


.btn {
  border-radius: 0;
  padding: 10px 20px;

  box-shadow: none;
}

.btn:hover,
.btn:focus {
  box-shadow: none;
}

.btn-outline-primary {
  border-color: $red-color;
  color: $text-color;
  font-weight: 500;

  &:hover {
    background: $red-color;
    border-color: $red-color;

    svg {
      color: $white-color;
    }
  }
}

.mini-para-heading {
  font-size: 24px;
  line-height: 30px;
  color: #2b2523;
  margin: 15px 0px 5px;

  @media (max-width:786px) {
    font-size: 20px;
    line-height: 24px;
  }
}
.interLink{
  color: #f69557;
  &:hover{
    color: #f69557;
  }
}
.para-heading {
  font-size: 30px;
  line-height: 35px;
  color: #2b2523;
  margin: 24px 0px 20px;

  @media (max-width:786px) {
    font-size: 20px;
    line-height: 24px;
    margin-top: 0px;
  }
}

.para {
  font-size: 18px;

  @media (max-width:786px) {
    font-size: 16px;
  }
}

.btn-primary {
  border: none !important;
  outline: none !important;
}


.btn-r {
  background-color: $red-color;
  border: $red-color;
  color: $white-color !important;

  &:focus,
  &:hover {
    background-color: $red-color  !important;
  }
}

.btn-b {
  background-color: $blue-color;
  border: $blue-color;

}

.rounde-img {
  border-radius: 5px;
}

.shadow-img {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

// p {
//   text-align: justify;
// }

.btn-g {
  background-color: $green-color;
  border: $green-color;

  &:hover {
    background-color: $green-color;

  }
}

.btn-o {
  background-color: $orange-color;
  border: $orange-color;
}

.btn-play {
  padding: 0;
  border: none;
  background: transparent;
  outline: none;
  color: $blue-color;
  font-size: 18px;
  width: 150px;
  display: inline-block;

  .icon {
    padding-right: 10px;
  }
}

.max-w-1000 {
  max-width: 1000px;
}

.section-heading {
  color: #474c55;
  font-weight: 500;
  margin-bottom: 2rem;
}

.modal-gray .modal-content,
.bg-gray {
  background: #fafafa;
}

.bg-blue {
  background: #eef3f9;
}

.bg-red {
  background: $red-color;
  color: $white-color;

  h1 {
    color: $white-color;
  }
}

.bg-orange {
  background: $orange-color;
  color: $white-color;

  h1 {
    color: $white-color;
  }
}
h1.section-heading,
h2.section-heading {
  font-size: 2.5rem;
}
@media screen and (max-width: 767px) {

  h1.section-heading,
  h2.section-heading {
    font-size: 1.5rem;
    margin: 1.5625rem 0;
    margin-top: .5rem;
    line-height: 1.875rem;
  }
    .container {
      padding-top: 35px;
      padding-bottom: 35px;
    }
}

.section-subheading {
  max-width: 900px;
  font-size: 1.7rem;
  font-weight: 500;
  margin: 0 auto 0.7em;
  color: #474c55;

  @media (max-width:786px) {
    font-size: 1.5rem;
  }
}

.w-1000 {
  max-width: 1000px;
  width: 100%;
}

// .modal-backdrop.show {
//   position: fixed;
//   z-index: 9999999;
// }
.pos-r{
  position: relative;
}
.pos-a{
  position: absolute;
}
.t-0{
  top: 0;
}
.l-0{
  left: 0;
}
.modal {

  // z-index: 9999999999999;
  button {
    border: none;
    box-shadow: none !important;
  }
}

.modal-dialog {
  max-width: 700px;
  width: 100%;

  .modal-header {
    border-bottom: 0;
    padding-bottom: 0;

    .modal-title {
      font-size: 20px;
      border-bottom: 2px solid $blue-color;
    }
  }

  .modal-footer {
    border-top: 0px;
    padding-top: 0px;
  }

  .modal-content {
    margin: 10px;
    padding: 20px;
    border-radius: 0;
    @media (max-width:786px) {
      padding: 10px;
    }
  }
}

.rmt-card {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 30px;
  height: 100%;
  border-radius: .2rem;
  transition: .2s ease-in-out;

  &.shadow-none {
    box-shadow: none;
  }

  &.rmt-feature {
    .icon {
      width: 120px;
      height: 120px;
      margin: 0 auto;
    }

    h5 {
      font-size: 20px;
      margin-bottom: 10px;
    }

    p {
      font-size: 15px;
    }
  }

  &.rmt-card-light-blue {
    background: #DFF6FF;
  }

  h5 {
    margin-bottom: 20px;
  }

  p {
    &:last-child {
      margin: 0;
    }
  }

  &:hover {
    box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  }

  &.center {
    text-align: center;
  }
  @media (max-width:786px) {
    p{
      font-size: 14px;
    }
  }
}

.text-a-justify {
  text-align: justify !important;
}

.i-orange {
  color: $orange-color  !important;
}

.rmt-card-white {
  border: 1px solid #f2f2f2;
  background: #ffffff;
  color: $black-color;
}

.rmt-card-gray {
  border: 1px solid #f2f2f2;
  background: #e8e8e8;
  color: $black-color;
}

.rmt-card-blue {
  background: $blue-color;
  color: $white-color;
  border: 1px solid #C4DDFF;
}

.notFound{
  height: 110px;
}
.errorText{
  height: 20px;
  font-size: 14px;
  color: #EE4648;
  width: 100%;
  display: block;
}
.blog_image{
  background: #ccc;
}
@media (max-width:786px) {
  .mb-flex-col-with-10gap{
    flex-direction: column !important;
    gap: 15px;
    margin-bottom: 20px;
  }
  .modal.show .modal-dialog{
    margin: 0 !important;
  }
}
.row-awards .readMoreBtn{
  color: #EE4648;
  margin: 0;
  font-weight: 500;
  cursor: pointer;
}
@media (min-width:786px) {
  .ms-d-auto{
    margin-left: auto;
  }
}
.form-group{ 
  position: relative;
  .form-error-msg{
    position: absolute;
    bottom: -15px;
    padding: 0;
    margin: 0;
    color: $red-color;
    font-size: 10px;
  }
}