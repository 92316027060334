$text-color: #3f3f3e;
$red-color: #EE4648;
$blue-color: #42bdec;
$orange-color: #f69557;
$white-color: #fff;
$green-color: #32CF6C;

.parnter-page {
    .component {
        padding: 20px;

        .icon {
            font-size: 60px;
            margin-bottom: 10px;
            color: $blue-color;
        }
    }
}

.pi_sec {
    .col-12 {
        margin-bottom: 20px;
    }

    .rmt-card {
        margin: 10px;
        padding: 50px;

        .feat_icon svg {
            width: 100px;
            height: 100px;
            color: $blue-color;
        }

        @media (max-width:786px) {
            padding: 10px;
            margin: 0;
            .feat_icon svg {
                width: 60px;
                height: 60px;
                margin-bottom: 10px;
            }
        }
    }
}

.SVGICON {
    padding: 20px;

    svg {
        width: 100px;
    }
}