@import '../../App.scss';

.award_page {
    .col-awards {
        margin-bottom: 20px;
        padding: 20px 40px;

        a{
            color: #EE4648;
            margin: 0;
            font-weight: 500;
            cursor: pointer;
          }

        @media (max-width:786px) {
            padding: 20px;
            margin-bottom: 0;
        }

        .img-awards {
            max-width: 100%;
            height: auto;
            margin-bottom: 0px;
            height: 180px;

            @media (min-width: 786px) {
                max-width: 100%;
                height: 200px;
            }

            @media (max-width: 576px) {
                max-width: 100%;
                height: 130px;
            }
        }



        label {
            font-weight: 500;
            color: #000000;
            margin-top: 5px;

            @media (min-width: 1200px) {
                font-size: 21px;
                line-height: 30px;
            }

            @media (min-width: 992px) {
                font-size: 21px;
                line-height: 30px;
            }

            @media (min-width: 768px) {
                font-size: 18px;
                line-height: 24px;
            }

            @media (min-width: 576px) {
                font-size: 21px;
                line-height: 30px;
            }
        }

        p {
            font-weight: 300;
            color: #000000;
            margin-top: 20px;

            @media (min-width: 992px) {
                font-size: 18px;
                line-height: 22px;
            }

            @media (min-width: 768px) {
                font-size: 15px;
                line-height: 22px;
            }

            @media (min-width: 576px) {
                font-size: 18px;
                line-height: 22px;
            }
        }

    }
}

.galleryAward {
    position: relative;
}

.viewButton {
    border-radius: .5rem !important;
    position: absolute;
    right: 20px;
    bottom: 20px;
    margin-left: auto;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    width: 90px;
    background: $white-color;
    color: $red-color;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px !important;
    span{
        padding-right: 8px;
    }
    svg path {
        padding-right: 10px;
        stroke: $red-color;
    }

    &:hover {
        background: $red-color;
        color: $white-color;

        svg path {
            stroke: $white-color;
        }
    }
}

.line-limit-5{
    overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 5; /* number of lines to show */
           line-clamp: 5; 
   -webkit-box-orient: vertical;
}