$text-color: #3f3f3e;
$red-color: #EE4648;
$blue-color: #42bdec;
$orange-color: #f69557;
$white-color: #fff;
$green-color: #32CF6C;
$black-color: #000;

.locationTab {
    // background: $blue-color;
    max-width: 1200px;
    margin: 0 auto;

    .navigation-tab {
        padding: 10px 0;
        max-width: 1200px;


        ul {
            margin-bottom: 0;
            display: flex;
            flex-direction: column;

            button {
                background: transparent;
                outline: none;
                border: none;
                margin-right: 20px;
                font-size: 1.15rem;
                font-weight: 500;

                &.active {
                    color: $orange-color;
                }
            }
        }
    }
}
//kuldeep

// img.img-fluid.city_moral {
//     height: 406px;
//     width: 400px;
// }


@media (max-width:991px) {
    .location-tabpanel .city_moral {
        width: 150px !important;
    }
}
.location-tabpanel {
    position: relative;
    margin: 20px auto;
    // max-width: 1200px;
    padding: 40px 0;

    @media (max-width:1300px) {
        padding: 20px 0;
    }

    &::before {
        content: "";
        background: $orange-color;
        width: calc(100% - 8.4375rem);
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
    }

    .location-content {
        margin-left: 30px;
        height: 100%;
        display: flex;
        align-items: center;
        color: $white-color;

        button {
            border: none;
            // font-size: 1.25rem;
            outline: none;
        }
    }

    .country-content {
        background: $white-color;
        height: 400px;
        position: relative;
        box-shadow: rgba(0, 0, 0, 0.15) 2.4px 2.4px 3.2px;
    }

    .city_moral {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 280px;
    }

    .get-direction {
        font-weight: 700;
    }

    .address-wrap {
        color: #474c55;
        font-size: 1rem;
        line-height: 1.2;
        padding: 0 6.25rem 0 3.125rem;
        position: absolute;
        left: 0;
        top: 50%;
        -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        // width: 79.5%;
        z-index: 4;

        a {
            color: $black-color;
        }

        address {
            // margin: 0;

            p {
                color: $black-color;
                margin: 0;
            }


        }

        .get-direction {
            margin-top: 0.9375rem;
            font-size: .875rem;
        }
    }.contact-number{
        p {
                color: $black-color;
                margin: 0;
                @media (min-width:786px){
                    br{
                        display: none;
                    }
                }
                @media (max-width:786px){
                    margin-bottom: 6px;
                }

            }
    }
    .Locationmap {
        width: 100%;
        height: 400px;
    }

    h4 {
        margin-bottom: 20px;
        color: $white-color;
    }

    p {
        color: $white-color;
    }

    p.title {
        margin-bottom: 0;
        font-weight: 600;
        font-size: 20px;
    }
}



@media (max-width:786px) {
    .locationTab {
        max-width: 1200px;
        margin: 0 auto;

        .navigation-tab {
            padding: 10px 0;
            max-width: 1200px;


            ul {
                margin-bottom: 0;
                display: flex;
                flex-direction: row;
                overflow-x: visible;

                button {
                    margin-bottom: 15px;
                    font-size: 14px;
                    padding: 5px 10px;
                }
            }
        }
    }

    .location-tabpanel {

        .location-content {
            margin-left: 0px !important;
            width: 100%;

            ul {
                margin-left: 5px;
                margin-right: 5px;
                display: flex;
                overflow-x: visible;
                flex-direction: row;

                button {
                    margin-bottom: 15px;
                    font-size: 14px;
                    padding: 5px 10px;
                }
            }
        }

        &::before {
            height: calc(100% - 1.875rem) !important;
            width: 100% !important;
        }

        .country-content {
            width: calc(100% - 40px);
            margin: 0 auto;
            height: 280px;
        }

        .address-wrap {
            font-size: .75rem;
            padding: 0.625rem 1.25rem;
            right: auto;
            width: 100%;
        }

        .city_moral {
            width: 150px;
        }
    }
}