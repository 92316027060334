@import "../../App.scss";

.contact_form {
    .bg-gray {
        padding: 1.875rem 4.0625rem;
    }

    .section-heading {
        color: #474c55;
        font-weight: 500;
        margin-bottom: 2.5rem;
    }

    .form-wrapper .form-heading {
        font-size: 1.125rem;
        font-weight: 400;
        margin-bottom: 1.875rem;
    }

    .form-group {
        margin-bottom: 0.9375rem;
    }

    .customcheckradio>span {
        margin-bottom: 1.5625rem;
    }

    .customcheckradio .check_list_item {
        display: block;
        margin-bottom: 1.5625rem;
        margin-left: 0;


        label {
            display: flex;
            align-items: center;

            .list-item-label {
                color: #474c55;
                font-size: 1rem;
            }
        }

        input {
            width: 20px;
            height: 20px;
            color: $orange-color;
            background: $orange-color;
            border-color: $orange-color;

            &::after {
                color: $orange-color;
                background: $orange-color;
                border-color: $orange-color;
            }
        }
    }


    .iradio_minimal-yellow {
        position: relative;
        margin-right: 10px;
        display: flex;
    }

    .form-group label.required:after {
        content: "*";
        color: #ee3b2d;
        margin-left: 0.3125rem;
    }

    .form-group label {
        color: #3f3f3e;
        font-size: .875rem;
        font-family: Muli, sans-serif;
        font-weight: 400;
        line-height: 1.125rem;
    }

    .form-group .form-control {
        border: 1px solid #ccc;
        background: transparent;
        padding: 10px 20px;
        font-size: 1rem;
        -webkit-box-shadow: none;
        box-shadow: none;
        border-radius: 0;
        margin: 5px 0 10px;
    }
}

@media screen and (max-width: 767px) {
    .contact_form {
        .form-wrapper {
            border: 1px solid hsla(0, 0%, 85.5%, .4);
            margin-top: 1.25rem;
            padding: 1.25rem;
        }

        .section-heading {
            margin-bottom: 1.5625rem;
        }

        .bg-gray {
            background: transparent;
            padding: 10px;
        }
    }
}