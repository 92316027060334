
.privacy-policy-title {
    color: #474c55;
    font-family: 'Poppins', sans-serif;
    /* font-weight: bold; */
    margin-bottom: 20px;
    font-size: 2.5rem;
    text-align: center;
}

.privacy-policy-section {
    margin-bottom: 40px;
}
.intro-heading {
    color: #F79558 !important;
}

.privacy-policy-section h2{
    font-size: 1.7rem;
    font-weight: 500;
    margin: 0 auto 0.7em;
    color: #474C55;
} 
.privacy-policy-section h3 {
    font-size: 1.4rem;
    font-weight: 500;
    margin: 0 auto 0.7em;
    color: #474C55;
}

.privacy-policy-section p, 
.privacy-policy-section ul {
    /* color: #7f8c8d; */
    font-family: 'Poppins', sans-serif;
    line-height: 1.6;
    margin-bottom: 15px;
    font-size: 16px;
    font-weight: 400;
    color: #474C55
}

.privacy-policy-section ul {
    padding-left: 20px;
}

.privacy-policy-section ul li {
    list-style: disc;
    font-weight: 400;
}

.privacy-policy-section a {
    color: blue;
    text-decoration: none;
}

.privacy-policy-section a:hover {
    text-decoration: underline;
}
.inline-list-item {
    display: inline-block;
    margin-right: 10px; /* Adjust the spacing between list items */
    vertical-align: top;
}

.inline-list-item p {
    display: inline;
    margin: 0; /* Remove default margin from <p> */
}