@import "../../App.scss";


.testimonial_section .container {
    display: flex;
    flex-direction: column;
    justify-content: center;

    .section-subheading {
        margin-bottom: 40px;
    }

    .testi_icon {
        padding: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 60px;
        height: 60px;
        font-size: 2rem;
        border-radius: .2rem;
        color: $blue-color;
        margin-bottom: 10px;
    }

    .rmt-card {
        padding: 20px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .testamonials_img {
            width: 150px;
            margin-bottom: 20px;
        }

        p {
            font-size: 14px;
            text-align: center;
            color: $text-color;
        }

        h3 {
            font-size: 20px;
            margin: -0;
        }
        @media (max-width:786px) {
            &.details-page{
                padding: 10px;
                img{
                    width: 100%;
                }
            }
        }
    }




    .swiper-button-prev,
    .swiper-button-next {
        z-index: 999999;

        &::after {
            color: $white-color;
            font-size: 16px;
            width: 30px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px;
            background: $blue-color;
        }
    }
}
                @media screen and (max-width: 640px) {
                    .logo_slider .logo_block {
                        width: 150px !important;
                    }
                }
.logo_slider {
    width: 100%;

    .logo_block {
        padding: 10px 20px;
    }
}
.max-length-5{
    overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 12; /* number of lines to show */
           line-clamp: 12; 
   -webkit-box-orient: vertical;
}
.readMoreBtn{
    font-weight: 500;
    color: #EE4648;
    &:hover{
        color: #cf2d30 !important;
    }
}
.productImageWrapper {
    // width: 100%;
    // height: 400px;
    // overflow: hidden;

    img {
        &.desktop_assets {
            display: block;
        }

        &.mobile_assets {
            display: none;
        }
    }

    @media (max-width:786px) {
        img {
            &.desktop_assets {
                display: none;
            }

            &.mobile_assets {
                display: block;
            }
        }
    }
}

@media (max-width:786px) {
    .testimonial_row{
        flex-direction: row;
        overflow: auto;
        .col-md-4{
            flex: auto;
            flex-shrink: 0;
            scroll-snap-align: start;
        }
    }
}