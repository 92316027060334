.body {
    font-family: 'Poppins', sans-serif;
}
.container {
  max-width: 1400px;

}
.bcontent {
  margin-top: 5px;
}
.card-title {
  margin-bottom: 10px; /* Adjust the bottom margin as needed */
}

.card-text {
  margin-top: 10px; /* Adjust the top margin as needed */
}

.w-45 {
width: 350px;

}
@media (max-width: 768px) {
  .card-img {
      width: 80%; /* Ensure the image fits within the container */
      height: auto; /* Adjust height if needed */
  }
  
  .w-75 {
      width: 80%; /* Adjust width for mobile screens */
      height: auto; /* Adjust height if needed */
  }
  
  .w-55 {
      width: 80%; /* Adjust width for mobile screens */
  }
}
.card-body {
  height: 100%; /* Ensures the body takes full height of the container */
  display: flex;
  flex-direction: column;
}

.card-text {
  overflow: hidden; /* Hides overflow text */
  text-overflow: ellipsis; /* Adds ellipsis for overflow text */
}

.image-background {
  background-color: '#FFDDC8';
  border-radius: '25px';
  overflow: 'hidden';
  display: 'flex';
  justify-content: 'center';
  align-items: 'center';
  height: '320px' 
}
.content {
  position: relative;
  padding: 2.5rem 1.5rem;

  margin: 0.5rem;
  border-radius: 0.25rem;
 
  background-image: url('../image/bgcolor.jpg'); 
  background-size: cover;
  background-position: center; /* Center the image */
  background-repeat: no-repeat; 
 
}
.w75 {
  max-width: 1800px;
}
.advantageMainContainer {
  padding: 2rem 0;
  text-align: center;
}

.advantageMainContainer h2 {
  margin-bottom: 2rem;
}

.table-responsive {
  margin-top: 2rem; /* Add some space above the table */
}

.table {
  width: 100%;
  border: none; 
  background-color: white;
}

.table td {
  padding: 1rem;
  vertical-align: middle;
  border: none; /* Remove default table cell borders */
}

.advantage-item {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  text-align: center;
}

.advantage-item img {
  width: 50px;
  height: 50px;
  margin-right: 10px;
}

.advantage-item p {
  margin: 0;
}

.industry-section{
  text-align: center; 
}
.choose-us-section{
  text-align: center; 

}

.accordion {
  background-color: #eee;
  color: #444;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
  transition: 0.4s;
}

.active, .accordion:hover {
  background-color: #ccc; 
}

.panel {
  padding: 0 18px;
  display: none;
  background-color: white;
  overflow: hidden;
}

.s32 {
  /* padding-top: 4pt; 
  padding-left: 15pt;  */
  /* text-indent: 0pt; */
  text-align: justify;
  margin-bottom: 1rem;
  font-size: 22px;
  font-weight: 500; 
}
.bold-link {
  font-weight: bold;
}

.s32 + .s32 {
  padding-top: 5pt; 
}
.single_service_text {
  padding: 0 1rem;
}
.bold-h1 {
  font-weight: bold;
}
.requestAdemo {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: .75rem 4rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: .25rem;
}

.single_service_single_box_img_title {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 0.2rem 1.2rem;
  margin-bottom: -25px;
  gap: 0.2rem 0.5rem;
}
.single_service_single_box {
  padding: 0px 25px 30px 0px;
  padding: 10px;
  box-shadow: 0rem 1.1rem 5rem -0.5rem #b7c4d9;
  background-color: #fff !important;
  text-align: left;
  border-radius: 40px 16px 16px 16px;
  height: 100%;
  transition: all ease 0.2s;
}
.single_service_single_box_img_title .single_service_img_box {
  width: 120px;
  margin-left: -2px;
  width: 90px;
  margin-left: -2px;
}
.single_service_single_box_img_title .single_service_img_box img {
  width: 100%;
  width: 130%;
}
.custom-bg {
  background-image: linear-gradient(
    to right bottom,
    #fabc95,
    #fcc9a8,
    #fed5bb,
    #ffe2cf,
    #ffeee3
  );
  padding: 20px 0;
}
.card {
  height: 100%;
}
.card-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.bg-red {
  background-color: #EE4648;
  
}
.custom-section {
    background-color: white;
    opacity: 0.9;
    padding: 4rem 0;
}
.custom-item {
    height: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.custom-text {
    font-size: 50px;
    font-weight: bold;
    text-align: center;
}
.custom-subtext {
    font-size: 18px;
    color: #343a40;
    font-weight: bold;
    color: black;
}
.text-red {
    color: red;
}
.text-green {
    color: green;
}
.text-blue {
    color: blue;
}
.card_x .card-body{
  padding: 1rem;
}
.banner {
  /* background-image: url('./banner.png');  */
  background-size: cover;
  background-position: center;
  height: 100vh;
  color: white;
  display: flex;
  align-items: center;
}

.myvideo-container .banner-content .banner-content-box {
  /* background: rgba(0, 0, 0, 0.5);
  padding: 20px;
  border-radius: 10px; */
  background: rgba(0, 0, 0, 0.5);
  padding: 20px;
  border-radius: 10px;
  width: 100%;
  max-width: 850px;
}
.myvideo-container .banner-content .banner-content-box h1,
.myvideo-container .banner-content .banner-content-box h5 {
  margin-bottom: 20px;
}
.form-container {
  background: white;
  padding: 20px;
  border-radius: 10px;
}
h1 {
  font-weight: 500;
}

h5 {
  font-weight: 600; 
}

li {
  /* font-weight: 600;  */
  /* list-style-type: none;  */
  /* position: relative; */
  /* padding-left: 1.5em;  */
}

li::before {
  /* content: "■";  */
  position: absolute;
  left: 0;
  font-size: 1em;
  color: white; 
}

.accordion {
  background-color: #f1f1f1;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
  transition: 0.4s;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.panel {
  padding: 0 18px;
  background-color: white;
  display: none;
  overflow: hidden;
}

.icon {
  transition: transform 0.4s;
}

.active .icon {
  transform: rotate(180deg);
}
.banner {
  padding: 20px;
}

.banner-content h1 {
  font-weight: bold;
}

.banner-content h5 {
  font-weight: 600;
}

.banner-content ul {
  list-style-type: none;
  padding: 0;
}

.banner-content li {
  font-weight: 600;
  position: relative;
  padding-left: 1.7em;
  margin-bottom: 10px;
}

.banner-content li::before {
  content: "\f00c"; 
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  position: absolute;
  left: 0;
  color: rgb(245, 90, 18); 
  font-size: 18px;
}

.w75 {
  width: 75%;
}
.advantage-item img {
  max-width: 100px; 
  margin-bottom: 10px;
}
.advantage-item p {
  margin: 0;
}
.industry-section {
  text-align: center;
  margin-bottom: 30px;
}

.myvideo-container {
  position: relative;
}
.myvideo {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: -1;
}
.Enquire-content h2{
  margin-bottom: 20px;
}
.single_service_title {
  align-self: center;
  font-weight: 600;
  margin: 0;
  /* font-size: 14px; */
  /* font-size: 21px; */
  font-size: 20px;
  color: #161616;
  margin-top: -30px;
}
.annoucementtwo{
  z-index: 0;
}


@media only screen and (max-width: 1048px){
.banner-content,
.Enquire-content{
  flex: 0 0 auto;
      /* width: 100%; */
  }

  .banner-content{
    flex: 0 0 auto;
        width: 50%;
    }

.Enquire-content{
  flex: 0 0 auto;
      width: 50%;
  }
  
  .myvideo-container .banner-content .banner-content-box{
    max-width: 100%;
    margin-bottom: 20px;
    border-radius: 10px;
  }
  .Enquire-content .rmt-card{
    
    margin-bottom: 20px;
    border-radius: 10px;
  }
}


@media only screen and (max-width: 991px){
  .banner {
    background-size: cover;
    background-position: center;
    height: 100%;
    padding-top: 80px;
  }
  .myvideo-container .banner-content {
    margin-bottom: 15px;
  }
  .myvideo-container .banner-content .banner-content-box{
    width: 100%;
    max-width: 100%;
  }
  .rmt-card {
    border-radius: 10px;
  }
  .rmt-card{
    width: 100%;
  }
  .annoucementtwo{
    z-index: 0;
    position: unset;
  }
  .banner-content,
.Enquire-content{
  flex: 0 0 auto;
    width: 100%;
  }


}
@media only screen and (max-width: 767px){
  .single_service_optiMLQMS3 .d-flex {
    flex-direction: column !important;
}
.single_service_optiMLQMS3 .d-flex .w-75 {
  width: 100% !important;
  margin: 0 !important;
  margin-bottom: 10px !important;
}
.single_service_optiMLQMS3 .d-flex .w-55 {
  width: 100% !important;
  margin-top: 20px !important;
}

/* .popup-onload {
  position: fixed;
  background: #ffffff;
  padding: 20px;
  box-shadow: 0px 0px 10px rgb(0 0 0 / 54%);
  z-index: 1000;
  width: 90% !important;
  max-width: 600PX;
  border-radius: 10px;
  box-shadow: 0 8px 28px rgba(0, 0, 0, .28);
  bottom: 20px;
  left: 5% !important;
  transform: unset !important;
  margin: 0 auto;
} */

}



@media only screen and (max-width: 767px){
  .myvideo {
    height: 110%;
}
.myvideo {
  /* object-position: -340px 0px; */
}
}