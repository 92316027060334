@import '../../App.scss';

.main_certification_container {
    max-width: 1000px;
    margin: 0 auto;

    .row {
        img {}
    }
}

.main_certification_data_div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-bottom: 30px;

    .certification_img_div img {
        border: 2px solid $red-color;
    }

    .certification_img_div.right {
        margin-right: 50px;
    }

    .certification_img_div.left {
        margin-left: 50px;
    }

    .certification_content_div {
        .title {
            font-size: 30px;
            font-weight: 500;
            margin-bottom: 20px;
        }

        .certification_content {
            font-size: 16px;
            color: #7a7a7a;
        }
    }
    @media (max-width:786px) {
        flex-direction: column;
        &.left{
            flex-direction: column-reverse;
        }
        .certification_img_div{
            &.left,&.right{
                margin: 0;
            }
            img{
                margin-bottom: 20px;
            }
        }
    }
}