.thankyou-page {
    text-align: center;
    margin-top: 100px; /* Adjust the value as needed for the gap from the top */
    margin-bottom: 50px; /* Adjust the value as needed for the gap at the bottom */
}

.thankyou-content {
    margin-top: 20px; /* Adjust the value as needed for the gap between the image and content */
}

.home-button {
    margin-top: 20px; /* Adjust the value as needed for the gap between the content and the button */
    padding: 10px 20px;
    font-size: 16px;
    background-color: #f80505;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

/* .home-button:hover {
    background-color: #f80505;
} */
