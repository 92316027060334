$text-color: #3f3f3e;
$red-color: #EE4648;
$blue-color: #42bdec;
$orange-color: #f69557;
$white-color: #fff;
$green-color: #32CF6C;

.becomepartner_page {
    .parnter_container {
        margin: 0 auto;

        p {
            font-size: 18px;
            // text-align: center;
            margin: 0;
            padding-bottom: 8px;
        }
    }

    .main_cont_wrap {
        padding: 20px 40px;

        img {
            max-width: 100%;
            margin-bottom: 20px;
        }

        h5 {
            font-size: 24px;
        }

        @media (max-width:786px) {
            padding: 0;
            margin-bottom: 20px;
        }
    }
}

.level_partners {
    .rmt-card {

        .card_header {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 10px;
        }

        .award_icon {
            margin-bottom: 15px;
            font-size:60px;

            &.sliver {
                color: #d0d0d0;
            }

            &.gold {
                color: #FFD700;
            }

            &.platinum {
                color: #999999;
            }
        }

        h5 {
            margin-bottom: 0px;
            font-size: 24px;
        }

        p {
            font-size: 15px !important;
        }
    }
}

.partners-bg-img {
    color: $white-color;
    background: url(../../../public/assets/image/bg-banner.jpg);

    p.heading {
        font-size: 20px;
        font-weight: 500;
        color: $text-color;
    }
}