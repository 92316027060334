@import "node_modules/@glidejs/glide/src/assets/sass/glide.core";

@import "node_modules/@glidejs/glide/src/assets/sass/glide.theme";

$white-color: #fff;
$black-color: #080808;
$text-color: #3f3f3e;
$red-color: #EE4648;
$blue-color: #0099ff;
$orange-color: #f69557;
$green-color: #32CF6C;
$red-color-light: rgba(238, 70, 72, .2);

.story_img {
    max-width: 700px;
    margin: 0 auto;
}

.story__timeline {
    margin-top: 50px;
    position: relative;

    .timeline__year {
        position: relative;
        font-size: 1.125rem;
        line-height: 1.375rem;
        font-weight: 700;

        &::after {
            content: '';
            display: block;
            position: absolute;
            margin: 10px 0;
            width: 1px;
            bottom: -36px;
            left: 20px;
            height: 24px;
            background: $red-color;
            z-index: 999999;
        }
    }

    .timeline__content {
        position: relative;
        padding-right: 34px;

        &::before {
            content: '';
            display: block;
            top: 0;
            left: 0;
            position: absolute;
            margin: 10px 0;
            width: 100%;
            height: 1px;
            background: $red-color;
            z-index: 999999;
        }

        img {
            padding-top: 30px;
            height: 180px;
            width: 100%;
            object-fit: cover;
            margin-bottom: 1rem;
            border-radius: 3px;
        }
    }

}

.timline_story-abt {
    .glide__arrows {
        position: relative;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: end;
        -ms-flex-pack: end;
        justify-content: flex-end;
        font-family: Keap Icons;
        min-width: 5.6875rem;
        border-left: 2px solid #0f0f0f;
        height: 100%;

        button {
            position: relative;
            left: unset;
            right: unset;
        }

        .glide__arrow--left,
        .glide__arrow--right {
            height: 100%;
            display: block;
            box-shadow: none;
            border: none;
            width: auto;
            font-size: 30px;
            color: $black-color;
            color: $black-color;
        }
    }
}

.story__row {
    .story__sec {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 20px;

        .icon {
            flex-shrink: 0;
            color: $white-color;
            border-radius: 50%;
            width: 70px;
            height: 70px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 25px;
            background: $blue-color;
            margin-right: 15px;
        }


        .story__content {
            .year {
                font-size: 12px;
                color: $black-color;
                margin-bottom: 2px;
                color: rgba(0,0,0,.8);
            }

            p {
                font-weight: 500;
                font-size: 14px;
                line-height: 16px;
                color: $text-color;
                margin: 0;
                &.subtitle{
                    font-weight: 400;
                }
            }
        }
    }
    .col-12{
        &:first-child{
            .icon{
                background: $blue-color;
            }
        }
        &:nth-child(2){
            .icon{
                background: $green-color;
            }
        }
        &:nth-child(3){
            .icon{
                background: $orange-color;
            }
        }
        &:nth-child(4){
            .icon{
                background: $red-color;
            }
        }
        &:nth-child(5){
            .icon{
                background: $blue-color;
            }
        }
        &:nth-child(6){
            .icon{
                background: $blue-color;
            }
        }
        &:nth-child(7){
            .icon{
                background: $orange-color;
            }
        }
        &:nth-child(8){
            .icon{
                background: $green-color;
            }
        }
        &:nth-child(9){
            .icon{
                background: $red-color;
            }
        }
        &:nth-child(10){
            .icon{
                background: $blue-color;
            }
        }
    }
}