@import '../../App.scss';

.jobop__container {
    padding: 35px;
    border: 1px solid hsla(0, 0%, 85.5%, .4);
    margin-bottom: 20px;
    transition: .2s ease-in-out;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .job__title {
        font-weight: 700;
        font-size: 1.25rem;
        margin-bottom: 0.8125rem;
    }

    p {
        margin-bottom: 0.5rem;
    }

    p.countrys {
        color: $blue-color;
        font-size: 1rem;
    }

    &:hover {
        -webkit-box-shadow: 0 0 0.375rem #f0f0f0;
        box-shadow: 0 0 0.375rem #f0f0f0;
    }

    .appy-action {
        display: flex;
        justify-content: flex-end;
    }
}

.pe-20 {
    padding-right: 20px;
}

.link-job {
    font-weight: 500;
    color: $text-color;
    font-size: 18px;
    display: flex;
    align-items: center;
    flex-direction: row;
    width: 207px;
    height: 40px;
    overflow: hidden;

    .icon {
        position: relative;
        width: 22px;
        height: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 15px;
        color: $white-color;
        background: $blue-color;

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 22px;
            height: 22px;
            display: block;
        }
    }


    &:hover {
        color: $blue-color;

        .icon {
            &::after {
                width: 300px;
            }
        }
    }
}

@media (max-width:786px) {
    .jobop__container {
        padding: 15px;
        flex-direction: column;


        .appy-action {
            margin-top: 10px;
            display: flex;
            justify-content: flex-end;
        }
    }
}

@media (max-width:786px) {
    .career-page {
        .career-sec {

            .row {
                .career {
                    margin-bottom: 20px;
                }

                .client-content {
                    padding: 0 !important;
                }
            }
        }
    }
}