@import "../../App.scss";
@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@500&display=swap');

.rmt-row {
    margin-top: 20px;
}


.left-nav.show-nav-mobile .show-hide {
    right: -10px;
}

.left-nav.show-nav-mobile .show-hide svg {
    transform: rotate(180deg);
}

.mirrorx {
    transform: rotateY(180deg);
    -webkit-transform: rotateY(180deg);
    /* Safari and Chrome */
    -moz-transform: rotateY(180deg);
    /* Firefox */
}

.heriocSection {
    margin: 0 !important;
    position: relative;
    width: 100%;
    height: 100vh;
    background-color: rgba(12, 8, 9, .39);

    video {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        object-fit: cover;
        z-index: -1;
    }

    .h-hero__section {
        position: relative;
        display: flex;
        height: 100%;
        -webkit-box-orient: vertical;
        flex-direction: column;
        justify-content: center;
        //background-color: rgba(12, 8, 9, .01);
        color: #fff;
        text-align: left !important;
    }

    h2 {
        color: $white-color;
        font-size: 60px !important;
        font-family: 'Rubik', sans-serif;
        line-height: 62px;
    }

    p {
        font-size: 18px !important;
        line-height: unset;
        opacity: .8;
    }

    @media (max-width:786px) {
        h2 {
            color: $white-color;
            line-height: 44px;
            font-size: 40px !important;
            font-family: 'Rubik', sans-serif;
        }

        p {
            font-size: 14px !important;
            line-height: unset;
            opacity: .8;
        }
    }
}


.fact-card-container {
    padding: 0 30px 35px;
    margin-bottom: 20px;
    transition: .3s ease-in-out;
    text-align: center;

    .icon {
        width: 150px;
        margin: 0 auto;

        svg {
            fill: $blue-color;
        }
    }

    .card-title {
        .dot {
            display: inline-block;
        }

        h5 {
            margin: 0;
            text-align: center;
            font-size: 20px;
        }
    }

    .card-desc {
        padding-top: 10px;

        p {
            font-size: 15px;
            // line-height: 20px;
            margin: 0;
        }
    }

    &:hover {
        background: #f5f5f5;
    }

    &.fact-bg {
        padding: 15px;
        height: 350px;

        svg {
            fill: $white-color;
        }

        &:hover {
            color: $black-color;
            background: $white-color;

            svg {
                fill: $red-color;
            }
        }
    }

    @media (max-width:786px) {
        padding-top: 0px;
        padding-bottom: 0px;
        margin-bottom: 0px;
    }
}

.rmt-tick-card-container {
    margin: 0;

    li {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 15px 0;
        font-weight: 500;
        font-size: 16px;

        .icon {
            font-size: 18px;
            padding-right: 10px;
            color: $green-color;
        }

        h5 {
            margin: 0;
            font-size: 16px;
            font-weight: 500;
            letter-spacing: -.2px;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

}

.annoucement {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    overflow: hidden;
    padding: 5px 0;
    bottom: 0;
    background: $blue-color;
    margin: 0;

    p {
        width: 100%;
        margin: 0;

        span {
            font-weight: 500;
        }
    }

    .marquee,
    .w-inline-block {
        max-width: 100%;
        // width: 800px;
        display: inline-block;
    }

    .marquee {
        margin: 0 auto;
        overflow: hidden;
        white-space: nowrap;

        .marqueeContent {
            display: inline-block;
            position: relative;
            left: 100%;
            animation: marquee 20s linear infinite;

            &:nth-child(1) {
                animation-delay: 0s;
            }

            &:nth-child(2) {
                animation-delay: 0.2s;
            }

            &:nth-child(3) {
                animation-delay: .4s;
            }

            &:nth-child(4) {
                animation-delay: .8s;
            }

            .sperator {
                color: $white-color;
                padding-left: 30px;
            }
        }

        &:hover .marqueeContent {
            animation-play-state: paused;
        }

        @keyframes marquee {
            0% {
                left: 100%;
            }

            100% {
                left: -100%;
            }
        }
    }

    .nav__banner-link-cms {
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        text-decoration: none;
        color: $white-color;
        font-size: 14px;

        .nav__banner-icon {
            display: inline;
            margin-right: 5px;
            color: $white-color;
            font-size: 1rem;

            svg {
                path {
                    stroke: $white-color;
                }
            }
        }

        @media (max-width:786px) {
            font-size: 12px;
        }
    }

    .close-btn {
        position: absolute;
        right: 100px;
        top: 50%;
        transform: translateY(-50%);

        @media (max-width:786px) {
            right: 10px;
        }

        .btn1 {
            border: none;
            outline: none;
            color: $white-color;
            background: transparent;
            font-size: 1rem;
        }
    }
}

@media (max-width:786px) {
    .home__showreel {
        .h-hero__heading-wrap {
            padding: 10px;

            .subheading {
                font-size: 14px !important;
                margin-bottom: 10px !important;
            }

            h1 {
                line-height: 45px !important;
            }
        }

        .btn {
            margin-top: 20px;
        }

        .annoucement {

            .nav__banner-icon,
            .nav__banner-link-cms {
                font-size: .8rem;
                box-shadow: none;
            }
        }
    }
}

.businessCard {
    margin: 0px 0 50px;

    .business-img {
        width: 100%;
        height: 250px;

        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .business-text {
        width: 100%;
        float: left;
        padding: 15px 25px;
        border-top-color: transparent;
        background: #f5f5f5;
        text-align: left;

        h6 {
            font-weight: 700;
        }

        h4 {
            font-size: 20px;
            margin-bottom: 10px;
            color: $black-color;
            line-height: 1.2;
            -webkit-line-clamp: 3;
        }

        hr {
            margin: 5px 0;
        }

        ul {
            list-style: unset;
            padding-left: 20px;
            margin-bottom: 0px;
        }

        .rightArrowBtn {
            position: relative;
            font-weight: 500;
        }
    }
}

.Sales_banner {
    border-radius: .5rem;
    position: relative;
    background: $blue-color;
    padding: 80px;
    color: $white-color;
    margin-top: 50px;
    margin-bottom: 40px;
    height: 400px;
    display: flex;
    align-items: center;

    .sales-img {
        position: absolute;
        pointer-events: none;
        right: 2%;
        bottom: 0;
        width: 600px;

        img {
            width: 100%;
        }
    }

    .btn-w {
        background: #fff;
        color: #000;
    }

    .title {
        font-size: 40px;
        font-weight: 500;
        max-width: 900px;
        color: $white-color;
        margin-bottom: 20px;
    }

    p {
        font-size: 16px;
        max-width: 500px;
    }

    .sales {
        &__actionbtn {
            margin-top: 30px;

            .btn {
                font-weight: 500;
                font-size: 17px;
                text-align: center;
            }
        }
    }
}

@media (max-width:1200px) {
    .Sales_banner {
        height: 300px;
        padding: 50px;
        justify-content: start;

        .title {
            font-size: 700;
            max-width: 700px;
            line-height: unset;
            font-size: 1.7rem;
        }

        .sales {
            &__actionbtn {
                margin-top: 30px;

                .btn {
                    font-weight: 500;
                    font-size: 18px;
                    text-align: center;
                }
            }
        }
    }
}

@media (max-width:1024px) {
    .Sales_banner {
        padding: 100px 10px;
        height: unset;
        text-align: center;
        justify-content: center;

        img {
            display: none;
        }

        .sales {
            &__actionbtn {
                margin-top: 30px;

                .btn {
                    font-weight: 500;
                    font-size: 18px;
                    text-align: center;
                }
            }
        }
    }
}

//CHANGES ADDED BY KULDEEP

@media (max-width:786px) {
    .Sales_banner {
        border-radius: 0;
        margin-bottom: 0px !important;
    }
}

.fact {
    padding: 10px 0;

    .section-heading {
        font-size: 25px;
        max-width: 800px;
        margin: 0 auto;
    }

    .facts_fig {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .section-heading {
            margin-bottom: 30px;
        }
    }

    // kuldeep changes
    .map {
        position: relative;
        width: 55%;
        opacity: .3;
        height: 90%;
        margin: 0 auto;

        svg {
            width: 100%;
        }
    }

    .fact .map {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .facts_f {
        padding: 20px;
        text-align: center;

        &.red .facts_num {
            color: $red-color;
        }

        &.green .facts_num {
            color: $green-color;
        }

        &.blue .facts_num {
            color: $blue-color;
        }

        .facts_num {
            font-size: 60px;
            font-weight: 900;
        }

        .facts_title {
            font-weight: 500;
        }
    }
}

@media (max-width:700px) {
    .fact {
        padding: 10px 0;

        .section-heading {
            font-size: 25px;
            max-width: 800px;
            margin: 0 auto;
        }

        .facts_fig {
            position: relative;

            .section-heading {
                margin-bottom: 30px;
            }
        }

        .map {
            position: relative;
            width: 100%;
            opacity: .3;
            height: 100%;
            margin-bottom: 40px;

            svg {
                width: 100%;
            }
        }

        .facts_f {
            padding-bottom: 10px;

            p {
                margin-bottom: 0;
            }
        }
    }
}

.left-nav {
    position: fixed;
    left: 0px;
    top: 200px;
    z-index: 200;
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    -webkit-transform: translateX(-92%);
    transform: translateX(-92%);
    color: $black-color;
    transition: .2s ease-in-out;

    &.hidden {
        opacity: 0;
        visibility: hidden;
        user-select: none;
    }

    &.show {
        opacity: 1;
        visibility: visible;
        user-select: unset;
    }

    &.md {
        display: none;

        @media (max-width:786px) {
            display: block;
        }

        &.show-nav-mobile {
            -webkit-transform: translateX(0);
            transform: translateX(0);
        }

        .show-hide {
            background-color: #FFF;
            display: block;
            color: $red-color;
            font-size: 20px;
            right: -10px;
            top: 50%;
            border-bottom-right-radius: 10px;
            border-top-right-radius: 10px;
            padding: 10px;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            cursor: pointer;
            box-shadow: 0 4px 8px 0 rgb(0 0 0 / 15%);
            margin: 0 auto;
            z-index: -1;
            position: absolute;
        }
    }

    .container {
        position: relative;
        z-index: 100;
        width: 100%;

        .row {
            width: 100%;
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -webkit-flex-direction: column;
            -ms-flex-direction: column;
            flex-direction: column;
            padding: 1rem 0px !important;
            -webkit-transition: all .2s ease-in-out;
            transition: all .2s ease-in-out;
            box-shadow: 0 4px 8px 0 rgb(0 0 0 / 15%);
            background: #FFF;
            border-radius: .5rem;
            border-top-left-radius: 0px;
            border-bottom-left-radius: 0px;
        }
    }

    .grid-item {
        display: flex;
        padding: 5px 10px;
        width: 100%;

        svg {
            font-size: 20px;
            color: $red-color;

            path {
                stroke: $red-color;
            }
        }

        .rounded-sm {
            color: $black-color;
            font-size: 14px;
            width: 200px;

            p {
                padding-left: 10px;
                margin: 0;
            }
        }


        a {
            color: $black-color;
            font-size: 14px;
            width: 200px;

            p {
                padding-left: 10px;
                margin: 0;
            }
        }

        &:hover {
            svg {
                color: $white-color;

                path {
                    stroke: $white-color;
                }
            }

            a {
                color: $white-color;
            }
        }

        &:nth-child(1) {
            svg {
                color: $blue-color;

            }

        }

        &:nth-child(2) {
            svg path {
                stroke: $green-color;
            }
        }


        &:nth-child(3) {
            svg {
                color: $orange-color;

            }
        }

        &:hover {
            svg {
                color: $white-color;

                path {
                    stroke: $white-color;
                }
            }
        }

        &:nth-child(1):hover {
            background: $blue-color;
        }

        &:nth-child(2):hover {
            background: $green-color;

        }

        &:nth-child(3):hover {
            background: $orange-color;
        }
    }



    &.show-nav-mobile {
        -webkit-transform: translateX(0);
        transform: translateX(0);
    }

    &.desk {
        // width: 100px;
        -webkit-transform: translateX(-0%);
        transform: translateX(-0%);
        user-select: none;


        .row {
            .grid-item {
                padding: 10px 15px;
                width: unset;
                display: flex;
                align-items: center;
                overflow: hidden;
                width: 75px;
                transition: .2s ease-in-out;

            }

            svg {
                font-size: 25px;
            }

            p {
                padding-left: 15px;
                width: 200px;
                opacity: 1;
                transition: .3s ease-in-out;
            }
        }


        &:hover {

            .row {
                .grid-item {
                    width: 100%;
                }
            }

        }

        @media (max-width:786px) {
            display: none;

        }
    }
}

//kuldeep

// @media screen and (min-width: 768px) and (max-width: 1023px) {
//     .websiteurl {
//       font: smaller;
//     }
// }


.card-background {
    justify-content: center;
    border: none;

}

.card-body {
    padding: 0;

}

.list-group-item {
    background-color: rgba(7, 7, 7, 0.534);
    border: none;
    // position: initial;
}

.list-group-flush {
    border-radius: 0;
    position: absolute;
    margin-top: -55px;
}

.websiteurl {
    color: #f5f5f5;
    display: flex;
    font-size: smaller;
    text-decoration: underline;
}


.map-container {
    max-width: 80%;
    height: auto;
}




//added by kuldeep
//13-10-2023


.bg-map {
    background-image: url('../../assets/Images/map.png');

    background-repeat: no-repeat;
    background-position: center center;


}

.bg-map {

    position: relative;
    min-height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.bg-map::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    opacity: 31%;
    z-index: -0.2;

}

.row {
    display: flex;

    flex-wrap: wrap;
}

@media (max-width: 768px) {
    .bg-map {

        background-position: top center;
    }
}