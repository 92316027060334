@import "../../App.scss";

.headerBanner {
    margin: 0 !important;
    position: relative;
    width: 100%;
    background-color: rgba(12, 8, 9, .39);
    height: 300px;
    overflow: hidden;

    .h-hero__section {
        padding-top: 50px;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        -webkit-box-orient: vertical;
        flex-direction: column;
        justify-content: center;
        text-align: left !important;
        background: linear-gradient(90deg, rgba(188, 0, 0, 0.48) 0.63%, rgba(156, 0, 98, 0.48) 19.26%, rgba(131, 0, 176, 0.48) 34.27%, rgba(0, 33, 205, 0.48) 65.32%, rgba(0, 240, 255, 0.48) 95.86%),
            linear-gradient(0deg, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6));
        color: $white-color;

        h2 {
            max-width: 1400px;
            width: 100%;
            margin: 0 auto;
        }
    }


    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    p {
        color: $white-color;
        margin-bottom: 10px;
        font-size: 18px;
        font-weight: 300;
    }

    h2 {
        color: $white-color;
        font-weight: 500;
        font-size: 35px;
    }

    .h-hero__heading-wrap .subheading {
        letter-spacing: unset;
    }

    @media (max-width:786px) {
        height: 220px;
        h2 {
            margin: 0;
            font-size: 45px;
            line-height: 35px;
        }

        p{
            font-size: 14px;
        }
    }

}