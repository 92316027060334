/* styles/FAQSection.css */
.accordion {
    background-color: #f1f1f1;
    border: none;
    cursor: pointer;
    padding: 10px;
    text-align: left;
    outline: none;
    font-size: 15px;
    transition: 0.4s;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .panel {
    padding: 0 18px;
    background-color: white;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
  }
  
  .icon {
    font-size: 20px;
    color: #333;
    transition: transform 0.4s;
  }
  
  .accordion:focus {
    outline: none;
  }
  